<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <!-- <vuexy-logo /> -->
        <b-link :to="{ name: 'auth-login' }">
          <img class="logo" :src="logoImage" height="50" alt="" />
        </b-link>
        <!-- <h2 class="brand-text text-primary ml-1">
          Vuexy
        </h2> -->
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imgUrl" alt="Register V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Reset password-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            {{ $t("auth.recover_password") }} 🔒
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t("auth.password_different") }}
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form class="auth-reset-password-form mt-2" method="POST" @submit.prevent="validationForm">

              <!-- password -->
              <b-form-group
              :label="$t('auth.new_password')"
                label-for="reset-password-new"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="Password"
                  :rules=passwordRules
                  :custom-messages="{ required: $t('validators.required') }"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="reset-password-new"
                      v-model="password"
                      :type="password1FieldType"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      name="reset-password-new"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon class="cursor-pointer" :icon="password1ToggleIcon"
                        @click="togglePassword1Visibility" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- confirm password -->
              <b-form-group label-for="reset-password-confirm" :label="$t('auth.password_confirm')">
                <validation-provider #default="{ errors }" name="Confirm Password" rules="required|confirmed:Password">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input id="reset-password-confirm" v-model="cPassword" :type="password2FieldType"
                      class="form-control-merge" :state="errors.length > 0 ? false : null" name="reset-password-confirm"
                      placeholder="············" />
                    <b-input-group-append is-text>
                      <feather-icon class="cursor-pointer" :icon="password2ToggleIcon"
                        @click="togglePassword2Visibility" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit button -->
              <b-button block type="submit" variant="primary">
                {{ $t("auth.confirm_password") }}
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{ name: 'auth-login' }">
              <feather-icon icon="ChevronLeftIcon" /> {{ $t("auth.back_to_login") }}
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Reset password-->
    </b-row>
  </div>
</template>

<script>

import { checkTokenReset, resetPassword } from '@/api/routes'
import axios from 'axios';
import { maxPassword } from '@/main.js'

/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BCardTitle, BCardText, BForm, BFormGroup, BInputGroup, BInputGroupAppend, BLink, BFormInput, BButton, BImg,
} from 'bootstrap-vue'
import { max } from 'vee-validate/dist/rules';
import { required, password } from '@validations'

import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CryptoJS from "crypto-js";

export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BImg,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      email: '',
      cPassword: '',
      password: '',
      passwordRules: '',
      // sideImg: require('@/assets/images/pages/reset-password-v2.svg'),
      sideImg: require('@/assets/images/pages/login/intro.png'),
      // validation
      required,

      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',

      passwordChanged: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/reset-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    logoImage() {
      switch (process.env.VUE_APP_URL) {
        case 'https://pro.services.smileandlearn.net':
          return require('@/assets/images/logo/logo_login.png');
        case 'https://dev.services.smileandlearn.net':
          return require('@/assets/images/logo/logoSmileDev.png');
        case 'https://pre.services.smileandlearn.net':
          return require('@/assets/images/logo/logoSmilePRE.png');
        default:
          return require('@/assets/images/logo/logo_login.png');
      }
    },
  },
  created() {
    let URLlang = this.$route.query.lang;
    let langs = ['en', 'es', 'fr', 'pt', 'ca', 'it', 'la']
    if (URLlang && langs.includes(URLlang)) {
      this.$i18n.locale = URLlang;
    }
    extend('required', {
      ...required,
      message: this.$t('forms.errors.required'),
    });
    extend('password', {
      validate: (value) => {
        return /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*._,;+\-])(?=.{8,})/.test(value)
      },
      message: this.$t('forms.errors.password'),
    });
    extend('max', {
      ...max,
      message: (field, { length }) => this.$t('forms.errors.max_characters', { length })
    });
    this.passwordRules = 'required|password|max:' +  maxPassword
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    async validationForm() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success) {
          const token = this.$route.query.token;
          if (token != null) {
            try {
              await this.checkTokenInURL(token);
              await this.resetPasswordFunction(this.password);
              if (this.passwordChanged) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$t('auth.password_success'),
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                });

                // Redirigir a Home
                this.$router.push('/login');
              }
            } catch (error) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t('auth.password_error'),
                  description: error.message,
                  icon: 'AlertIcon',
                  variant: 'danger',
                },
              });
            }
          }
        }
      });
    },

    checkTokenInURL() {
      const token = this.$route.query.token;
      if (token) {
        let data = {
          token: token
        };
        this.$http.post(checkTokenReset, data).then(response => {
          if (response.data.status === 200 && response.data.msg === "Token OK") {
            this.passwordChanged = true;
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.msg,
                icon: 'AlertIcon',
                variant: 'danger',
              },
            });
            this.passwordChanged = false;
          }
        }).catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.msg,
              icon: 'AlertIcon',
              variant: 'danger',
            },
          });
          this.passwordChanged = false;
        });
      }
    },

    async resetPasswordFunction(password) {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get('token');
      const email = urlParams.get('email');

      const restorePass = {
        email: email,
        password: password,
        password_confirm: password,
        token: token
      };

      await this.$http.post(resetPassword, restorePass).then(response => {
        if (response.data.status === 200) {
          this.passwordChanged = true;
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('auth.password_error'),
              description: response.data.msg,
              icon: 'AlertIcon',
              variant: 'danger',
            },
          });
          this.passwordChanged = false;
        }
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('auth.password_error'),
            description: this.$t('error_retry_later'),
            icon: 'AlertIcon',
            variant: 'danger',
          },
        });
        this.passwordChanged = false;
      });
    },

    mounted() {
      this.checkTokenInURL();
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

@media screen and (orientation:landscape) {
  .logo {
    margin-bottom: 20px;
    height: 35px; 
  }
}
</style>